<template>
  <div id="BrandZoneDetail">
    <!-- 品牌详情 -->
    <Header id="Header" class="BrandZone_Header"></Header>
    <div class="position_box">
      <div class="center_position">
        <img
          class="backImg"
          @click="backvideolist"
          src="../../assets/img/back_home.png"
          alt=""
        />
        <div class="home" @click="backvideolist">返回品牌专区</div>
        <div class="current">当前位置：</div>
        <div class="home1" @click="backHome">首页></div>
        <div class="video_center" @click="$router.push({path:'/BrandZoneList',query:{active:0}})">品牌专区></div>
        <div class="video_center" @click="backvideolist">{{ $route.query.active }}></div>
        <div class="video_detail">{{ objData.name }}</div>
      </div>
    </div>
    <div class="detailProduct_box">
      <div class="left_swiper">
        <swiper
          ref="mySwiper"
          style="width: 100%; height: 100%"
          :options="Optionsmin"
        >
          <swiper-slide
            class="border_box"
            v-for="(item, index) in objData.image"
            :key="index"
          >
            <img class="my_cover" :src="item" alt="" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="right_box">
        <div class="name">{{ objData.name }}</div>
        <div class="info">{{ objData.info }}</div>
        <div class="size_box">
          <div class="size_top_box">
            <div class="size_lable">尺寸：</div>
            <div class="size_select_box">
              <el-select
                v-model="size"
                placeholder="请选择"
                @change="changeselect"
              >
                <el-option
                  v-for="item in objData.size"
                  :key="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="size_tost_box">
            产品尺寸手动测量，或存在1-2cm误差，产品颜色请以实物为准
          </div>
        </div>
        <div class="price_box">
          <div class="price_lable_box">价格：</div>
          <div class="price_box_num">￥ {{ objData.price }}</div>
          <div class="price_text">市场统一零售价</div>
        </div>
        <div class="btn_box" @click="to_search">查看附近门店</div>
      </div>
    </div>
    <LineTitle style="margin-bottom: 40px"></LineTitle>
    <div class="parameter_box">
      <!-- <div class="center_box" v-html="objData.text"></div> -->
      <div class="center_box">
        <div class="margin_div">
          <div
            class="attr_item"
            v-for="(item, index) in objData.attr"
            :key="index"
          >
            <span v-if="item.name != '' && item.info != ''" class="attrName"
              >{{ item.name }}：</span
            >
            <span v-if="item.name != '' && item.info != ''" class="attrInfo">{{
              item.info
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <LineTitle
      :line_zh="line_zh"
      style="margin-bottom: 50px; margin-top: 80px"
    ></LineTitle>
    <div class="details_box">
      <swiper
          v-if="objData.details_image.length"
        ref="mySwiper"
        style="width: 100%; height: 100%"
        :options="Optionsdetails"
      >
        <swiper-slide
          style="width: 264px"
          v-for="(item, index) in objData.details_image"
          :key="index"
        >
          <div class="details_slide">
            <img class="my_cover" :src="item" alt="" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import LineTitle from "../../components/lineTitle";
import Header from "../../components/Header";
import { getProductInfo } from "../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Header,
    LineTitle,
  },
  data() {
    return {
      Optionsmin: {
        slidesPerView: 1,
        autoplay:true,
        loop:true,
        pagination: {
          el: ".swiper-pagination",
        },
      },
      Optionsdetails: {
        slidesPerView: 4.2,
        slidesPerGroup: 1,
        spaceBetween: 30,
      },
      size: "",
      objData: {},
      line_zh: "产品细节",
    };
  },
  methods: {
    backHome() {
      this.$router.push("/");
    },
    backvideolist() {
      this.$router.go(-1);
    },
    to_search() {
      this.$router.push({
        path: "/service",
        query: {
          crumbs_active: 1,
        },
      });
    },
    changeselect() {
      console.log(this.size);
    },
    getProductInfoData() {
      getProductInfo({
        id: this.$route.query.id,
      }).then((res) => {
        this.objData = res.data;
        // console.log(res);
      });
    },
  },
  mounted() {
    this.getProductInfoData();
  },
};
</script>

<style scoped lang='scss'>
#BrandZoneDetail {
  width: 100%;
  .position_box {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #e5e5e5;
    .center_position {
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      .backImg {
        width: 30px;
        height: 30px;
        display: block;
        cursor: pointer;
      }
      .home {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        margin: 0 30px 0 20px;
        cursor: pointer;
      }
      .current {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        cursor: pointer;
      }
      .home1 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        cursor: pointer;
      }
      .video_center {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        cursor: pointer;
        color: #666666;
      }
      .video_detail {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #225928;
      }
    }
  }
  .detailProduct_box {
    width: 1200px;
    height: 424px;
    margin: 60px auto;
    display: flex;
    .left_swiper {
      width: 600px;
      height: 100%;
    }
    .right_box {
      width: 600px;
      height: 100%;
      background: rgba(0, 90, 63, 0.05);
      .name {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #005a3f;
        margin-top: 25px;
        margin-left: 47px;
      }
      .info {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin: 10px 0 25px 47px;
      }
      .size_box {
        width: 506px;
        height: 117px;
        background: #fff;
        margin: 0 47px 15px 47px;
        .size_top_box {
          margin: 16px 56px 19px 30px;
          display: flex;
          align-items: center;
          .size_lable {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 68px;
            color: #333333;
            white-space: nowrap;
          }
          .size_select_box {
            margin-left: 51px;
            width: 313px;
            height: 52px;
            border: 1px solid #f0f0f0;
          }
        }
        .size_tost_box {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          //   line-height: 68px;
          color: #999999;
          margin: 0 56px 11px 30px;
        }
      }

      .price_box {
        width: 506px;
        height: 77px;
        background: #fff;
        margin: 0 47px;
        display: flex;
        align-items: center;
        .price_lable_box {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 77px;
          color: #333333;
          margin-left: 30px;
        }
        .price_box_num {
          width: 176px;
          height: 52px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 52px;
          color: #333333;
          border: 1px solid #f0f0f0;
          text-align: center;
          margin-left: 53px;
          margin-right: 33px;
        }
        .price_text {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 77px;
          color: #999999;
        }
      }
      .btn_box {
        width: 506px;
        height: 43px;
        background: #005a3f;
        border-radius: 22px;
        margin: 25px 47px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 43px;
        color: #ffffff;
        text-align: center;
      }
    }
  }
  .parameter_box {
    width: 1200px;
    min-height: 590px;
    background: rgba(0, 90, 63, 0.05);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    .center_box {
      width: 98%;
      margin: 16px 0;
      min-height: 559px;
      background: #fff;
      .margin_div {
        margin: 0 50px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .attr_item:last-child {
          margin-bottom: 49px;
        }
        .attr_item {
          width: auto;
          min-width: 45%;
          height: 45px;
          margin-top: 50px;
          .attrName {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 45px;
            color: #005a3f;
          }
          .attrInfo {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 45px;
            color: #666666;
          }
        }
      }
    }
  }
  .details_box {
    width: 1200px;
    height: 264px;
    margin: 0 auto 158px auto;
    .details_slide {
      width: 264px;
      height: 100%;
    }
  }
}
</style>